import React, {useState} from 'react';
import {Typography, Divider, List, DatePicker, Radio} from 'antd';
import Utils from "../utils/Utils";
import moment from "moment";

const {RangePicker} = DatePicker;

//TODO

export default class ReportItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {"专升本": []}
        }
    }

    componentDidMount() {
        const _this = this
        Utils.getReportItem().then((e) => {
            console.log(e)
            _this.setState({
                data: e
            })
        })

    }

    render() {
        return (
            <div>
                <Divider orientation="left">专升本</Divider>
                <List
                    bordered
                    dataSource={this.state.data["专升本"]}
                    renderItem={item => (
                        <List.Item>
                            <div style={{padding: "10px"}}>
                                {item.province}

                            </div>
                            <RangePicker
                                showTime
                                defaultValue={[moment(item.startTime), moment(item.endTime)]}
                                onChange={(e) => {
                                    console.log(item);
                                    console.log(e);
                                    Utils.updateReportItem(item.id, (moment(e[0]._d)).format("YYYY-MM-DD HH:mm:ss"), (moment(e[1]._d)).format("YYYY-MM-DD HH:mm:ss"))
                                }}
                            />
                        </List.Item>
                    )}
                />
                <Divider orientation="left">专科</Divider>
                <List
                    bordered
                    dataSource={this.state.data["专科"]}
                    renderItem={item => (
                        <List.Item>
                            <div style={{padding: "10px"}}>
                                {item.province}

                            </div>
                            <RangePicker
                                showTime
                                defaultValue={[moment(item.startTime), moment(item.endTime)]}
                                onChange={(e) => {
                                    console.log(item);
                                    console.log(e);
                                    Utils.updateReportItem(item.id, (moment(e[0]._d)).format("YYYY-MM-DD HH:mm:ss"), (moment(e[1]._d)).format("YYYY-MM-DD HH:mm:ss"))
                                }}
                            />
                        </List.Item>
                    )}
                />
                <Divider orientation="left">本科</Divider>
                <List
                    bordered
                    dataSource={this.state.data["本科"]}
                    renderItem={item => (
                        <List.Item>
                            <div style={{padding: "10px"}}>
                                {item.province}

                            </div>
                            <RangePicker
                                showTime
                                defaultValue={[moment(item.startTime), moment(item.endTime)]}
                                onChange={(e) => {
                                    console.log(item);
                                    console.log(e);
                                    Utils.updateReportItem(item.id, (moment(e[0]._d)).format("YYYY-MM-DD HH:mm:ss"), (moment(e[1]._d)).format("YYYY-MM-DD HH:mm:ss"))
                                }}
                            />
                        </List.Item>
                    )}
                />
            </div>
        );
    }
}
