import React from 'react';

export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                add user
            </div>
        );
    }
}