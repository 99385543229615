import React from 'react';
import Utils from "../utils/Utils";
import {Tag, Table, Button} from "antd";

const columns = [
    {
        title: '姓名',
        dataIndex: 'studentName',
        key: 'studentName',
        fixed: 'left',
        width: 45,
    },
    {
        title: '状态',
        dataIndex: 'verifyStatus',
        key: 'verifyStatus',
        fixed: 'left',
        width: 40,
        render: e => {
            return (
                <Tag color={e == 1 ? 'green' : 'volcano'}>
                    {e == 1 ? '已确认' : '未确认'}
                </Tag>)
        }
    },
    {
        title: '报备省份',
        dataIndex: 'province',
        key: 'province',
        width: 50,
    },
    {
        title: '报备类型',
        dataIndex: 'schoolType',
        key: 'schoolType',
        width: 50,
    },
    {
        title: '性别',
        dataIndex: 'sex',
        key: 'sex',
        width: 35,
    },
    {
        title: '身份证号',
        dataIndex: 'cardId',
        key: 'cardId',
        width: 110,
    },
    {
        title: '手机号',
        dataIndex: 'studentPhoneNumber',
        key: 'studentPhoneNumber',
        width: 70,
    },
    {
        title: '考生号',
        dataIndex: 'studentId',
        key: 'studentId',
        width: 55,
    },
    {
        title: '毕业中学',
        dataIndex: 'graduateSchool',
        key: 'graduateSchool',
        width: 90,
    },
    {
        title: '拟报专业',
        dataIndex: 'major',
        key: 'major',
        width: 70,
    },
    {
        title: '高考成绩',
        dataIndex: 'score',
        key: 'score',
        width: 40,
    },
    {
        title: '报备人',
        dataIndex: 'recommendName',
        key: 'recommendName',
        width: 45,
    },
    {
        title: '报备人工号',
        dataIndex: 'recommendWorkId',
        key: 'recommendWorkId',
        width: 60,
    },
    {
        title: '报备时间',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 60,
    },
    {
        title: '确认时间',
        dataIndex: 'verifyTime',
        key: 'verifyTime',
        width: 60,
    },
    {
        title: '操作',
        dataIndex: 'confirm',
        key: 'action',
        width: 60,
        render: e => {
            console.log(e)
            if (e > 0)
                return (
                    <div>
                        <a onClick={() => {
                            console.log(e)
                            Utils.verify(e)

                        }}>确认</a>
                    </div>
                )
            return (<div/>)
        }
    },
];
export default class ReportData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [{key: "1"}],
        }
    }

    componentDidMount() {
        const _this = this
        Utils.getReportDara({offset: 1, length: 2100000000}).then((e) => {
            console.log(e)
            _this.setState({
                data: e.data
            })
        })
    }

    render() {
        return (
            <div>
                <Button type="primary" onClick={async () => {
                    const response = await Utils.getReportDataExportFile()
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '生源报备系统 '+new Date().toLocaleString()+' 导出报备数据.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }}>导出数据</Button>
                <div style={{padding:'10px 0'}}/>
                <Table dataSource={this.state.data} columns={columns} scroll={{x: 1500, y: 500}}/>
            </div>
        );
    }
}