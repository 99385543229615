import React from "react";
import {Button, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import Utils from "./utils/Utils";

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            captchaImg: Utils.noCaptcha,
            captchaUUID: '',

        }
        this.updateCaptcha()
    }

    componentDidMount() {

    }

    render() {
        const onFinish = values => {
            console.log('Received values of form: ', values);
        };
        return (
            <div style={{
                "width": "300px",
                "height": "200px",
                "margin": "-150px 0 0 -100px",
                "display": "block",
                "position": "absolute",
                "left": "50%",
                "top": "50%"
            }}>
                <div style={{"textAlign": "center", "paddingBottom": "20px", "fontSize": "20px"}}>
                    <div>生源报备系统</div>
                    <div>管理平台</div>
                </div>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{remember: true}}
                    onFinish={(e) => {
                        console.log(e)
                        Utils.login({
                            ...e,
                            ...{
                                uuid: this.state.captchaUUID
                            }
                        }).then((e)=>{
                            if(!e) this.updateCaptcha()
                        })
                    }}
                >
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: 'Please input your Username!'}]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="用户名"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Please input your Password!'}]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="密码"
                        />
                    </Form.Item>
                    <Form.Item
                        name="verifyCode"
                        rules={[{required: true, message: 'Please input Captcha!'}]}
                    >
                        <Input
                            prefix={<div onClick={() => this.updateCaptcha()} style={{
                                backgroundImage: 'url(' + this.state.captchaImg + ')',
                                "backgroundSize": "cover",
                                "height": "48px",
                                "width": "130px",
                                "margin": "-4px -25px -4px -11px"
                            }}/>}
                            placeholder="?"
                        />

                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" onClick={(e) => {
                            console.log(e)
                        }}>
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>

        )
    }


    updateCaptcha = (e) => {
        this.setState({
            captcha: ''
        })
        Utils.getCaptcha().then((data) => {
                this.setState(data)
            }
        )
    }
}