import React from 'react';
import {Button} from "antd";
import Utils from "../utils/Utils";

export default class ImportUser extends React.Component {
    render() {
        return (
            <div>
                <Button type="primary" onClick={() => {
                    window.open('批量导入用户模板.xlsx')
                }}>下载导入模板</Button>
                <div style={{padding:'10px 0'}}/>
            </div>
        );
    }
}