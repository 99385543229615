import React, {useState} from 'react';
import {Table, Input, InputNumber, Popconfirm, Form, Radio} from "antd";
import Utils from "../utils/Utils";
import StudentInfo from "../utils/Component";

const {Search} = Input;


export default class DataSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchType: 'cardId',
            data: null
        }

    }


    render() {
        return (
            <div>
                查找方式：
                <Radio.Group onChange={(e) => {
                    console.log(e)
                    this.setState({
                        searchType: e.target.value
                    })
                }} value={this.state.searchType}>
                    <Radio value={'cardId'}>身份证号</Radio>
                    <Radio value={'name'}>姓名</Radio>
                </Radio.Group>
                <div style={{marginTop: '20px'}}>
                    <Search placeholder={'请输入' + (this.state.searchType == 'name' ? '姓名' : '身份证号')} onSearch={(e) => {
                        console.log(e)
                        if (e)
                            Utils.searchUser(this.state.searchType, e).then((e) => {
                                console.log(e)
                                this.setState({
                                    data: e
                                })
                            })
                    }} style={{width: 200}}/>
                </div>
                <StudentInfo data={this.state.data}/>
            </div>
        );
    }
}