import React, {useState} from 'react';
import Utils from "./Utils";
import {Form, Input, Modal, Space, Button, Divider, Badge, Descriptions, Alert} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';

const {confirm} = Modal;
let obj = {
    studentName: '姓名',
    cardId: '身份证号',
    studentId: '考生号',
    sex: '考生性别',
    studentPhoneNumber: '手机号码',
    graduateSchool: '毕业中学',
    major: '拟报专业',
    score: '高考成绩',
    verifyStatus: '验证状态',
    schoolType: '报备类型',
    province: '报备省份',
    createTime: '报备时间',
    verifyTime: '确认时间',
    comment: '备注',
    recommendName: '推荐人',
    recommendPhoneNumber: '推荐人手机号',
    recommendWorkId: '推荐人工号',
}
const originData = [];
for (let i in obj) {
    originData.push({
        key: i,
        name: obj[i],
        value: '',
    });
}

let obj2 = {
    studentName: '姓名',
    cardId: '身份证号',
    studentId: '考生号',
    sex: '考生性别',
    studentPhoneNumber: '手机号码',
    graduateSchool: '毕业中学',
    major: '拟报专业',
    score: '高考成绩',
    comment: '备注',
}
const originData2 = [];
for (let i in obj2) {
    originData2.push({
        key: i,
        name: obj2[i],
        value: '',
    });
}


export default class StudentInfo extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            originData,
            list: [],
            changeModalVisible: false,
            updateForm: []
        }
    }


    render() {
        let _this = this
        let list = []
        if (this.props.data) {
            for (let i in this.props.data) {
                const item = originData.map((j) =>
                    <Descriptions.Item label={j.name}>{
                        j.key == 'verifyStatus' ?
                            (this.props.data[i][j.key] == '1' ?
                                <Badge status="success" text="已确认"/> :
                                <Space><Badge status="warning" text="未确认"/>
                                    <Button type="primary"
                                            ghost
                                            onClick={() => {
                                                Utils.verify(this.props.data[i].id)
                                            }}
                                    >确认</Button></Space>)
                            : this.props.data[i][j.key]
                    }</Descriptions.Item>
                )
                item.push(
                    <Descriptions.Item label='操作' span={3}>
                        <Space>
                            <Button onClick={(e) => {
                                console.log(this.props.data[i])
                                this.setState({
                                    changeModalVisible: true,
                                    updateForm: <div>
                                        <Form
                                            name="global_state"
                                            fields={originData2.map((j) => {
                                                return {
                                                    name: [j.key],
                                                    value: _this.props.data[i][j.key],
                                                }
                                            })
                                            }
                                            onFinish={(e) => {
                                                let f={}
                                                for(let k in e){
                                                    if(k=='studentPhoneNumber')f['phoneNumber']=e['studentPhoneNumber']
                                                    else if(k=='studentName')f['name']=e['studentName']
                                                    else f[k]=e[k]
                                                }
                                                console.log({...{id: _this.props.data[i].id.toString()}, ...f})
                                                _this.setState({changeModalVisible:false})
                                                Utils.updateReportData({...{id: _this.props.data[i].id.toString()}, ...f})
                                            }}
                                        >
                                            {originData2.map((j) =>
                                                <div style={{margin:'5px 0'}}>
                                                    <Form.Item
                                                        label={j.name}
                                                        name={j.key}
                                                        value={_this.props.data[i][j.key]}
                                                    >
                                                        <Input/>
                                                    </Form.Item>
                                                </div>)}
                                            <div>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        提交
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    </div>
                                })
                            }}>修改</Button>
                            <Button type="primary" danger onClick={() => {
                                console.log(this.props.data[i].id)
                                confirm({
                                    title: '您确定删除此报备条目吗？',
                                    icon: <ExclamationCircleOutlined/>,
                                    content: '此操作不可撤销，请谨慎操作！',
                                    okText: '删除',
                                    okType: 'danger',
                                    cancelText: '取消',
                                    onOk() {
                                        Utils.deleteReportData(_this.props.data[i].id)
                                        console.log('OK');
                                    },
                                    onCancel() {
                                        console.log('Cancel');
                                    },
                                });
                            }}>删除</Button>
                        </Space>
                    </Descriptions.Item>
                )
                list.push(<div style={{padding: '10px 0'}}>
                    <Descriptions bordered>{item}</Descriptions>
                    <Divider/>
                </div>)
            }
            console.log(list)
        }
        if (this.props.data) {
            return (
                <div>
                    <div style={{padding: '10px'}}>
                        <Alert message={'已为您查找到 ' + this.props.data.length + ' 条数据！'} type="success"/>
                    </div>
                    <div style={{padding: '10px'}}>
                        {console.log(this.state)}
                        {list}
                    </div>
                    <Modal
                        title="修改信息"
                        visible={this.state.changeModalVisible}
                        onOk={(e) => console.log(e)}
                        onCancel={(e) => _this.setState({changeModalVisible:false})}
                        okButtonProps={{ type:"link"}}
                        okText=" "
                        //cancelButtonProps={{  type:"link"}}
                        cancelText="关闭"
                    >
                        {this.state.updateForm}
                    </Modal>
                </div>
            );
        } else
            return <div/>
    }
}
