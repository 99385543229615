import React, {useState} from 'react';
import Utils from "../utils/Utils";
import {Form, Input, Button, Table, message} from "antd";
import {CheckOutlined} from "@ant-design/icons";

const columns = [
    {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
    },
    {
        title: '工号',
        dataIndex: 'workId',
        key: 'workId',
    },
    {
        title: '手机号码',
        key: 'phoneNumber',
        render: e => {
            console.log(e)
            return (
                <div>
                    <Form layout="inline" onFinish={(i) => {
                        console.log(i)
                        if (!i.phoneNumber)
                            message.warn("请输入内容！");
                        else
                            if(Utils.updateUserPhoneNumber(e.id, i.phoneNumber))
                                message.success("修改成功，请重新查询！");

                    }}>
                        <Form.Item
                            name="phoneNumber"
                        >
                            <div style={{width: '110px'}}><Input placeholder={e.phoneNumber} size={"small"}/></div>
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <div style={{width: '0px'}}>
                                    <Button
                                        htmlType="submit"
                                        type="link"
                                        size={"small"}>
                                        更新
                                    </Button>
                                </div>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            )
            return (<div/>)
        }
    },
    {
        title: '操作',
        key: 'action',
        render: e => {
            console.log(e)
            return (
                <div>
                    <Button type="text" danger onClick={() => {
                        console.log(e)
                        //Utils.verify(e)
                    }}>
                        重置密码
                    </Button>
                </div>
            )
            return (<div/>)
        }
    },
];


export default class UserManger extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [{key: "1"}],
            visible: false
        }
    }

    componentDidMount() {
        const _this = this
        Utils.getUsers({offset: 1, length: 2100000000}).then((e) => {
            console.log(e)
            _this.setState({
                data: e.data
            })
        })
    }


    render() {
        return (
            <div>
                <Button type="primary" onClick={async () => {
                    /*                    const response = await Utils.getReportDataExportFile()
                                        const url = window.URL.createObjectURL(new Blob([response.data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', '生源报备系统 '+new Date().toLocaleString()+' 导出报备数据.xlsx'); //or any other extension
                                        document.body.appendChild(link);
                                        link.click();*/
                }}>导出数据</Button>
                <div style={{padding: '10px 0'}}/>
                <Table dataSource={this.state.data} columns={columns} scroll={{x: 600, y: 2000}}/>
            </div>
        );
    }

}