import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';


import {HashRouter, Route, Switch} from 'react-router-dom';
import Home from "./Home";
import Login from "./Login";

class Main extends React.Component {
    constructor(props) {
        super(props);
        window.location.hash = !localStorage.getItem('token') ? "/Login" : "/Home"
    }

    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route path="/Login" component={Login}/>
                    <Route path="/Home" component={Home}/>
                </Switch>
            </HashRouter>

        )
    }
}

ReactDOM.render(<Main/>,
    document.getElementById('container'),
);