import React, {useState} from 'react';
import {Upload, Table, Input, InputNumber, Popconfirm, Form, Radio, Button} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import Utils from "../utils/Utils";


export default class batchVerify extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Button type="primary" onClick={() => {
                    window.open('批量验证用户模板.xlsx')
                }}>下载批量确认模板</Button>
                <div style={{padding: '10px 0'}}/>
                <Upload accept={'.xlsx'} customRequest={async (e) => {
                    console.log(e.file);
                    const response =await Utils.batchVerify(e.file);
                    console.log(response)
                    const filename = "export.xlsx"
                    const data = response.data
                    const url = window.URL.createObjectURL(new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}))
                    const link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', 'excel.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }}>
                    <Button icon={<UploadOutlined/>}>上传文件</Button>
                </Upload>
            </div>
        );
    }
}