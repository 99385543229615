import React from "react";
import {Breadcrumb, Layout, Menu} from "antd";
import {HomeOutlined, LaptopOutlined, NotificationOutlined} from "@ant-design/icons";
import {HashRouter, Route, Switch} from "react-router-dom";


import ReportData from "./Report/ReportData";
import DataSearch from "./Report/DataSearch";
import batchVerify from "./Report/batchVerify";
import AddUser from "./User/AddUser";
import ImportUser from "./User/ImportUser";
import UserManger from "./User/UserManger";
import ReportItem from "./System/ReportItem";

const {SubMenu} = Menu;
const {Header, Content, Sider} = Layout;


export default class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Layout style={{minHeight: '100%',}}>
                <Header className="header">
                    <div className="logo">
                        <img src={'./logo_01.png'}
                             style={{width: '100%'}}/>
                    </div>
                </Header>
                <Layout>
                    <Sider width={200} className="site-layout-background">
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['bbgl']}
                            style={{height: '100%', borderRight: 0}}
                            onSelect={(i) => {
                                console.log(i)
                                window.location.hash = i.key
                            }}
                        >
                            <Menu.Item key="home" icon={<HomeOutlined/>}>首页</Menu.Item>
                            <SubMenu key="bbgl" icon={<LaptopOutlined/>} title="报备管理">
                                <Menu.Item key="/Home/Report/ReportData">报备数据</Menu.Item>
                                <Menu.Item key="/Home/Report/DataSearch">报备查找</Menu.Item>
                                <Menu.Item key="/Home/Report/batchVerify">批量确认</Menu.Item>
                            </SubMenu>
                            <SubMenu key="yhgl" icon={<LaptopOutlined/>} title="用户管理">
                                {/*<Menu.Item key="/Home/UserManger/AddUser">添加用户</Menu.Item>*/}
                                <Menu.Item key="/Home/UserManger/UserManger">用户管理</Menu.Item>
                                {/*<Menu.Item key="/Home/UserManger/ImportUser">导入用户</Menu.Item>*/}
                            </SubMenu>
                            <SubMenu key="xtgl" icon={<NotificationOutlined/>} title="系统管理">
                                <Menu.Item key="/Home/System/ReportItem">报备条目</Menu.Item>
                            </SubMenu>
                            {/*<SubMenu key="grzx" icon={<LaptopOutlined/>} title="个人中心">*/}
                            {/*    /!*<Menu.Item key="/Home">修改密码</Menu.Item>*!/*/}
                            {/*</SubMenu>*/}
                        </Menu>
                    </Sider>
                    <Layout style={{padding: '0 24px 24px'}}>
                        <Breadcrumb style={{margin: '16px 0'}}>
                            欢迎使用生源报备管理系统！
                            <a onClick={() => {
                                localStorage.clear();
                                window.location.hash = "/Login"
                            }}> 退出登录</a>
                        </Breadcrumb>
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        >
                            <HashRouter>
                                <Switch>
                                    <Route path="/Home/Report/ReportData" component={ReportData}/>
                                    <Route path="/Home/Report/DataSearch" component={DataSearch}/>
                                    <Route path="/Home/Report/batchVerify" component={batchVerify}/>
                                    <Route path="/Home/UserManger/AddUser" component={AddUser}/>
                                    <Route path="/Home/UserManger/ImportUser" component={ImportUser}/>
                                    <Route path="/Home/UserManger/UserManger" component={UserManger}/>
                                    <Route path="/Home/System/ReportItem" component={ReportItem}/>

                                </Switch>
                            </HashRouter>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}